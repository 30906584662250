import Swiper from "../vendor/swiper";
import vars from "../_vars";

import {disableScroll} from "../functions/disable-scroll";
import {enableScroll} from "../functions/enable-scroll";
import {swiperTrackpadSlide} from "../functions/customFunctions";

const {
	diplomasGallery, reviewsSliders
} = vars;


diplomasGallery.length && diplomasGallery.forEach((slider) => {
	const swiper = slider.querySelector('.swiper-container');
	const sliderPrev = slider.querySelector('.arrow-button--prev');
	const sliderNext = slider.querySelector('.arrow-button--next');

	const mySwiper = new Swiper(swiper, {
		watchOverflow        : true,
		watchSlidesVisibility: true,
		slidesPerView        : 1,
		centeredSlides       : true,
		spaceBetween         : 25,
		slidesPerGroup       : 1,
		speed                : 400,
		loop                 : true,
		autoHeight           : true,

		navigation: {
			nextEl: sliderNext && sliderNext,
			prevEl: sliderPrev && sliderPrev,
		}
	});
})

reviewsSliders.length && reviewsSliders.forEach((slider) => {
	const swiper = slider.querySelector('.swiper-container');
	const sliderPrev = slider.querySelector('.arrow-button--prev');
	const sliderNext = slider.querySelector('.arrow-button--next');

	const mySwiper = new Swiper(swiper, {
		watchOverflow        : true,
		watchSlidesVisibility: true,
		centeredSlides       : true,
		spaceBetween         : 0,
		slidesPerGroup       : 1,
		speed                : 400,


		slidesPerView  : 1,
		slidesPerColumn: 2,

		navigation: {
			nextEl: sliderNext && sliderNext,
			prevEl: sliderPrev && sliderPrev,
		}
	});
})




























