export default {
	activeMode : 'active-mode',
	activeClass: "active",
	windowEl   : window,
	documentEl : document,
	htmlEl     : document.documentElement,
	bodyEl     : document.body,

	header    : document.querySelector(".header"),
	burger    : document.querySelectorAll('.burger'),
	mobileMenu: document.querySelector('.mobile-menu'),

	toTopBtn: document.getElementById('toTop'),


	heroGallery    : document.querySelector('.hero-gallery'),
	diplomasGallery: document.querySelectorAll('.diplomas-section__slider'),
	reviewsSliders : document.querySelectorAll('.reviews-slider'),


	overlay         : document.querySelector('[data-overlay]'),
	modals          : [...document.querySelectorAll('[data-popup]')],
	modalsButton    : [...document.querySelectorAll('[data-btn-modal]')],
	innerButtonModal: [...document.querySelectorAll('[data-btn-inner]')],


	dataInputs: document.querySelectorAll('.input-data'),
	inputsMessenger: document.querySelectorAll( "[data-popup='review'] input[name='messenger']"),


	mainLinks     : document.querySelectorAll('a[href^="/#"]'),
	observSections: document.querySelectorAll('[id]'),

}





