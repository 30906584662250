import {fadeIn, fadeOut} from "../functions/customFunctions";
import vars from '../_vars'

const {toTopBtn} = vars

document.addEventListener("DOMContentLoaded", function() {

	toTopBtn && toTopBtn.addEventListener('click', function() {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	});

});

