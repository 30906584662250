import AirDatepicker from 'air-datepicker';

import vars from '../_vars'
import {fadeIn, fadeOut} from "../functions/customFunctions";

const {
	dataInputs, inputsMessenger
} = vars;

document.addEventListener('DOMContentLoaded', function () {
	function checkSelectedRadio() {
		let selectedInput;
		for (let i = 0; i < inputsMessenger.length; i++) {
			if (inputsMessenger[i].checked) {
				selectedInput = inputsMessenger[i];
				break;
			}
		}


		if (!selectedInput) {
			console.log("Ничего не выбрано");
			return;
		}

		const row = selectedInput.closest('.form-modal__row');
		const textarea = row.querySelector('textarea');
		const discription = row.querySelector('.form-modal__descr');
		const textInput = row.querySelector('.input-nickname');

		if (row.querySelector('#noMessenger')) {
			if (selectedInput.id !== 'noMessenger') {
				fadeIn(textarea, 0, 'flex');
				fadeIn(discription, 0, 'flex');

				textInput.value = '';
			} else {
				fadeOut(textarea, 0);
				fadeOut(discription, 0);
				textInput.value = 'Нет, со мной не нужно связываться';
			}
		}


	}


	checkSelectedRadio();
	inputsMessenger.forEach(radio => {
		radio.addEventListener('change', () => checkSelectedRadio());
	});



	dataInputs.forEach(function (dataInput) {
		const inputParent = dataInput.closest('.form-modal__row');

		new AirDatepicker(dataInput, {
			inline: true,
			selectedDates: [new Date()],
			buttons: [
				{
					content: 'Сегодня',
					onClick: (dp) => {
						dp.selectDate(new Date());
					}
				},
				{
					content: 'Очистить',
					onClick: (dp) => {
						dp.clear();
					}
				}
			],
			dateFormat: 'yyyy-MM-dd',
			onTodayButtonClick: function () {
				this.selectDate(new Date());
			}
		})

		inputParent.querySelectorAll('button').forEach(function (btn) {
			btn.addEventListener('click', function (e) {
				e.preventDefault();
			})
		});

	})

	function renderHtml(object, key ,innerBox) {
		const {name, title, description, img} = object;

		const listItems = description.map(desc => `
				<li class="radio__inner-item">
						<span class="radio__inner-name">${desc.label}</span>
						<span class="radio__inner-value">${desc.value} ${desc.free_places ? `<i class="accent">(свободных мест: ${desc.free_places})</i>` : ''}</span>
				</li>
		`).join('');

		const markup = `
				<label class="radio" for="${key}">
						<input id="${key}" type="radio" value="${title}" name="${name}">
						<div class="radio__inner">
							<div class="radio__inner-content">
								<h3 class="radio__title">${title}</h3>
								<ul class="radio__inner-list">${listItems}</ul>
							</div>
							<img width="151" height="235" src="${img}" alt="image">
						</div>
						
				</label>
		`;

		innerBox.insertAdjacentHTML('beforeend', markup);
	}

	function loadAndDisplayRadio(jsonPath, box) {
		fetch(jsonPath)
				.then(response => response.json())
				.then(data => {
					const container = box.querySelector('.form-modal__box');
					const themes = data;

					Object.keys(themes).forEach(key => {
						renderHtml(themes[key], key, container)
					});


					if (box.dataset.popup === 'instructions') {
						const doneBox = document.querySelector('[data-popup="done-instructions"] .form-modal__box');

						box.addEventListener('click', function(event) {
							let target = event.target;
							while (target !== this) {
								if (target.matches('.radio')) {
									const key = target.getAttribute('for');
									doneBox.innerHTML = '';

									renderHtml(themes[key], key, doneBox);
									break;
								}
								target = target.parentNode;
							}
						});
					}


				})
				.catch(error => {
					console.error('Ошибка при загрузке данных:', error);
				});
	}

	const themeRadioParent = document.querySelector('.modal[data-popup="theme"]')
	const instructionsRadioParent = document.querySelector('.modal[data-popup="instructions"]')

	loadAndDisplayRadio('./themes.json', themeRadioParent);
	loadAndDisplayRadio('./instructions.json', instructionsRadioParent);




});

