import '../vendor/lightbox'
import vars from '../_vars';

const {heroGallery,diplomasGallery} = vars


lightGallery(heroGallery, {
	speed: 500,
	download: false,
});

diplomasGallery && diplomasGallery.forEach((item) => {
	lightGallery(item.querySelector('.swiper-wrapper'), {
		speed: 500,
		download: false,
	});
})

