import JustValidate from 'just-validate';
import Inputmask from "inputmask";
import {addCustomClass, removeCustomClass} from './customFunctions';
import {closeModal} from "../components/modals";
// import {initTelInput} from '../components/initTel';

// function getCountryCode(form) {
// 	return fetch('https://ipinfo.io/json?token=34a8b1f1b9f907')
// 			.then(response => response.json())
// 			.then(data => {
// 				initTelInput(data.country, form);
//
// 				return data.country;
// 			})
// }

export const validateForms = (selector, rules, afterSend, error) => {
	let form = selector;

	let validation = new JustValidate(form, {validateBeforeSubmitting: true});

	const telSelector = form?.querySelector('input[type="tel"]');

	if (!form) {
		console.error('Нет такого селектора!');
		return false;
	}

	if (!rules) {
		console.error('Вы не передали правила валидации!');
		return false;
	}

	if (telSelector) {
		const mask = new Inputmask("+38 (999) 999 99 99");
		mask.mask(telSelector);
	}

	for (let item of rules) {

		if (selector.querySelector(item.ruleSelector)) {
			if(item.ruleSelector === 'input[name="messenger"]') {
				validation.addRequiredGroup(item.ruleSelector, item.rules);
			} else {
				validation.addField(item.ruleSelector, item.rules);
			}
		}


	}

	validation.onSuccess((ev) => {
		addCustomClass(form, 'loader');
		let formData = new FormData(ev.target);

		let xhr = new XMLHttpRequest();

		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4) {
				if (xhr.status === 200) {
					closeModal();
					if (afterSend) {
						setTimeout(function () {
							afterSend(form.getAttribute('data-popup'));
						}, 400);
					}
					console.log('status 200');
				} else {
					closeModal();
					if (error) {
						setTimeout(function () {
							error();
						}, 400);
					}

				}
			}
		}

		xhr.open('POST', 'mail.php', true);
		xhr.send(formData);

		ev.target.reset();
	})
};