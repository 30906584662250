import { validateForms } from '../functions/validate-forms';
import vars from '../_vars';
import { modalClickHandler, closeModal} from '../components/modals'
import { removeClassInArray, addCustomClass, removeCustomClass } from "../functions/customFunctions";


const rules = [
  {
    ruleSelector: '.input-phone',
    tel: true,
    telError: 'Введите правильный номер',
    showErrorMessage: false,
    rules: [
      {
        rule: 'required',
        errorMessage: 'Введите номер телефона!',
      },
      {
        rule: 'minLength',
        value: 10,
        errorMessage: 'Поле должно содержать 10 символов',
      },
      {
        rule: 'customRegexp',
        value: /^(\+?[\d\s\-\(\)]{7,})?$/,
        errorMessage: 'Введите правильный номер',
      }
    ]
  },
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Минимум 3 символа',
        showErrorMessage: false,
      },
      {
        rule: 'maxLength',
        value: 30,
        errorMessage: 'Не должно превышать 30 символов',
      },
      {
        rule: 'required',
        errorMessage: 'Поле "Ваше имя" обязательно для заполнения',
      },
      {
        rule: 'customRegexp',
        value: /^[A-Za-zА-Яа-яЁё\s]+$/,
        errorMessage: 'Имя должно содержать только буквы и пробелы',
      },
    ]
  },
  {
    ruleSelector: '.input-mail',
    rules: [
      {
        rule: 'required',
        errorMessage: 'Поле "Email" обязательно для заполнения',
      },
      {
        rule: 'email',
        errorMessage: 'Введите корректный email',
      },
      {
        rule: 'minLength',
        value: 7,
        errorMessage: 'Email должен содержать минимум 7 символов',
      },
      {
        rule: 'maxLength',
        value: 30,
        errorMessage: 'Email не должен превышать 30 символов',
      }
    ]
  },
  {
    ruleSelector: '.input-data',
    rules: [
      {
        rule: 'required',
        errorMessage: 'Это поле обязательно для заполнения',
      }
    ]
  },
  {
    ruleSelector: '.input-time',
    rules: [
      {
        rule: 'required',
        errorMessage: 'Поле "Укажите удобное для вас время" обязательно для заполнения',
      },
      {
        rule: 'customRegexp',
        value: /^(\d{2}([:. -]\d{2})?)$/,
        errorMessage: 'Введите время в формате ХХ, ХХ:ХХ, ХХ.ХХ, ХХ-ХХ или ХХ ХХ',
      }
    ]
  },
  {
    ruleSelector: '.input-nickname',
    rules: [
      {
        rule: 'required',
        errorMessage: 'Это поле обязательно для заполнения',
      },
      {
        rule: 'minLength',
        value: 3,
        errorMessage: 'Должно содержать минимум 3 символа',
      },
      {
        rule: 'maxLength',
        value: 35,
        errorMessage: 'Не должно превышать 30 символов',
      }
    ]
  },
  // {
  //   ruleSelector: 'input[name="messenger"]',
  //   rules: [
  //     {
  //       rule: 'required',
  //       errorMessage: 'Пожалуйста, выберите месседжер для связи',
  //     }
  //   ]
  // },
  {
    ruleSelector: '.input-review',
    rules: [
      {
        rule: 'required',
        errorMessage: 'Текст отзыва обязателен к заполнению',
      },
      {
        rule: 'minLength',
        value: 7,
        errorMessage: 'Отзыв должен содержать минимум 7 символов',
      },
      {
        rule: 'maxLength',
        value: 500,
        errorMessage: 'Отзыв не должен превышать 500 символов',
      },
      {
        rule: 'customRegexp',
        value: /^(?!.*<(?:.|\n)*?>)(?!.*\bhttps?:\/\/\S+\b).*$/,
        errorMessage: 'Отзыв не должен содержать HTML-код или ссылки на сторонние ресурсы',
      }
    ]
  },

];

function handleModal(selector) {
  switch (selector) {
    case 'review':
      modalClickHandler('done-reviews', 'active');
      break;
    case 'instructions':
      modalClickHandler('done-instructions', 'active');
      break;
    default:
      modalClickHandler('done', 'active');
      break;
  }
}

const afterForm = (selector) => {
  handleModal(selector)
};

const error = () => {
  modalClickHandler('fail', 'active');
};


const forms = document.querySelectorAll('form.form-modal')

for (const form of forms) {
  validateForms(form, rules, afterForm, error);
}
