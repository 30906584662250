import { disableScroll } from '../functions/disable-scroll';
import { enableScroll } from '../functions/enable-scroll';
import vars from '../_vars';

import {
  toggleClassInArray,
  toggleCustomClass,
  removeCustomClass,
  removeClassInArray,
  addCustomClass,
  fadeOut, fadeIn
} from '../functions/customFunctions';
const {
	overlay,
	burger,
	mobileMenu,
	header,
	activeClass} = vars;

const catalogMenu = document.querySelector('.catalog-menu')
const mobileMenuHandler = function(overlay, mobileMenu, burger) {
	burger.forEach((btn) => {
		btn.addEventListener('click', function(e){
			e.preventDefault();

      // removeCustomClass(catalogMenu, activeClass);
			toggleCustomClass(mobileMenu, activeClass);
			toggleClassInArray(burger, activeClass);


			removeCustomClass(overlay );

			if (mobileMenu.classList.contains(activeClass)) {
				disableScroll();
				// fadeIn(mobileMenu,400, 'flex');
			} else {
				// fadeOut(mobileMenu,400, 'flex');
				enableScroll();
			}
		})
	});

}

export const hideMenuHandler = function(overlay, mobileMenu, burger) {
	enableScroll()
	removeCustomClass(mobileMenu,activeClass);
	removeClassInArray(burger,activeClass);
  // removeCustomClass(catalogMenu,activeClass);
	removeCustomClass(overlay);
}

if (overlay) {
	mobileMenuHandler(overlay,mobileMenu,burger);
	overlay.addEventListener('click', function(e){
		if (e.target.classList.contains('overlay')) {
			hideMenuHandler(overlay,mobileMenu,burger);
		}
	});

}










